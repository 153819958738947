<template>
  <main>
    <div class="top-wapper">
      <span class="title">发票类型 ：</span>
      <label>
        电子发票
      </label>
    </div>
    <div class="address-container" v-show="show">
      <empty
              text=""
              :showLive="false"
              v-if="false"
      ></empty>
    </div>
  </main>

  <footer>
    <ul class="footer-nav">
      <li class="footer-li add-address"></li>
      <li class="footer-li button" :class="disable ? 'disable' : ''">
        <a href="javascript:;" id="submit" @click.once="onSubmit"> 提交申请 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
  import { defineComponent, ref, reactive, computed } from "vue";

  import axios from "@/api/axios";
  import Toast from "@/components/UI/Toast";

  import Empty from "@/components/common/Empty.vue";
  import qs from "qs";
  import router from "@/router";

  export default defineComponent({
    name: "ApplyInvoice",
    components: {
      Empty,
    },

    setup() {
      let checkedIdList =ref(null);
      const type = ref("2");
      const disable = ref(false);
      let checkedIds = sessionStorage.getItem("fapiaoBid");
      if(checkedIds){
        checkedIdList = eval(checkedIds);
      }
      function onSubmit() {
        // let checkedIds = sessionStorage.getItem("fapiaoBid");
        const openInvoiceType = sessionStorage.getItem("openInvoiceType");
        if (checkedIds) {
          checkedIds = JSON.parse(checkedIds).join(",");
        }

        if (!openInvoiceType) {
          Toast({
            type: "warning",
            title: "开票类型错误，请重新选择开票类型！",
          });

          return false;
        }
        axios
                .post(
                        "/M/Server/DoSaveInvoiceApply",
                        qs.stringify({
                          checkedIds,
                          openInvoiceType,
                        }),
                        {
                          params: {
                            validate: true,
                          },
                        }
                )
                .then((res) => {
                  if (res.data.success) {
                    Toast({
                      type: "success",
                      title: res.data.msg,
                    });

                    disable.value = true;
                    localStorage.setItem("showRedPackage", 'true');
                    router.push({
                      name:'Invoice',
                      query:{
                        type:1
                      }
                    })
                  } else {
                    Toast({
                      type: "error",
                      title: res.data.msg,
                    });
                  }
                })
                .catch((err) => {
                  Toast({
                    type: "error",
                    title: err.msg || err,
                  });
                });
      }

      return {
        type,
        onSubmit,
        checkedIdList,
        disable,
      };
    },
  });
</script>


<style lang="scss" scoped>
  main {
    padding-bottom: 50px;
  }

  .top-order-wapper {
    padding: 10px;
    padding-top: 5px;
    label {
      font-size: 16px;
      color: #666;
    }
    .order-num-span{
      display: inline-block;
      width: 66px;
      font-size: 16px;
      color: #666;
    }
  }

  .top-wapper {
    padding: 10px;
    padding-top: 5px;
    border: 1px solid #eee;
    label {
      font-size: 16px;
      color: #666;
      margin-right: 0.3rem;
    }
  }

  select {
    font-size: 10px;
    width: 150px;
    border: 1px solid #999;
    height: 35px;
    padding-left: 10px;
    background: #fff;
  }

  .title {
    font-size: 16px;
    color: #666;
  }
  .address-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    font-size: 14px;
    color: #999;
    box-sizing: border-box;
  }

  .car-li {
    box-sizing: border-box;
    padding: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    border-bottom: 5.625px solid #f4f4f4;
    position: relative;
    padding-left: 70px;
    margin-right: 0;
    display: flex;
  }

  .car-li .checkbox {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 50%;
    margin-top: -10px;
    z-index: 10;
  }
  .car-li .checkbox .car-check {
    position: absolute;

    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    z-index: 10;
    opacity: 0;
  }

  .order-date {
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px 0 5px 0;
    padding-left: 10px;
    font-size: 14px;
    box-sizing: border-box;
    color: #666;
    position: relative;
  }

  .spend-date {
    position: absolute;
    left: 15px;
    top: 20px;
    color: #666;
  }
  .spend-date span {
    font-size: 21.938px;
    text-align: center;
    display: block;
  }
  .spend-date p {
    font-size: 13.95px;
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
  }

  .car-li .checkbox img {
    position: absolute;
    width: 20px;
    height: 20px;
    vertical-align: top;
    left: 0;
    top: 0;
    z-index: 5;
    display: none;
  }
  .car-li .checkbox::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 0;
    border: 1px solid #ccc;
    border-radius: 2.513px;
    box-sizing: border-box;
  }
  .car-li .product-pic img {
    width: 75px;
    height: 75px;
    position: absolute;
    left: 48.487px;
    top: 50%;
    margin-top: -37.5px;
  }

  .product-info .product-name {
    width: 16em;
    display: block;
    font-size: 14px;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-info .product-no {
    font-size: 13px;
    color: #999;
    width: 16em;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .poroduct-num {
    margin-top: 5px;
  }
  .poroduct-num .input {
    display: inline-block;
    border: 1px solid #eee;
    height: 25px;
    vertical-align: middle;
    box-sizing: border-box;
  }
  .poroduct-num input {
    width: 50px;
    height: 20px;
    outline: none;
    border: none;
    vertical-align: top;
    padding-top: 5px;
    box-sizing: border-box;
    color: #666666;
    text-align: center;
  }
  .poroduct-num .sub {
    display: inline-block;
    position: relative;
    left: 7.987px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    vertical-align: middle;
    background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d13.png")
    no-repeat center center;
  }
  .poroduct-num .add {
    display: inline-block;
    position: relative;
    left: -7.987px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    vertical-align: middle;
    background: #f4f4f4 url("https://www.bzwz.com/static/m/images/d14.png")
    no-repeat center center;
    background-size: 10px 10px;
  }

  .invoice {
    .car-li {
      padding-left: 15px;
      display: flex;
      align-items: center;
      v::deep {
        .ui-checkbox__label {
          display: flex;
          align-items: center;
        }
      }
    }
    .spend-date {
      left: 42px;
      position: static;
    }
  }

  :deep(.zm-checkbox__label) {
    display: flex;
    align-items: center;
    .spend-date span {
      line-height: 29px;
    }
  }

  .dashboard {
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    background-color: #fff;
    height: 50px;
    font-size: 14px;
    border-top: 1px solid #f4f4f4;
    line-height: 50px;
    color: #666;
    padding-left: 15px;

    :deep(.zm-checkbox__label) {
      display: inline-block;
    }
  }
  .pay {
    position: absolute;
    right: 10px;
    top: 0;
    width: 100px;
    height: 35px;
    background-color: #d64b4b;
    color: #fff;
    border-radius: 35px;
    text-align: center;
    line-height: 35px;
    top: 50%;
    margin-top: -17.5px;
  }
  .pay a {
    color: #fff;
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    border-top: 1px solid #f4f4f4;
    font-size: 14px;
    color: #666;
    background-color: #fff;
    z-index: 100;
  }
  footer a {
    font-size: 14px;
    color: #666;
    display: block;
  }

  .footer-li {
    float: left;
    width: 17%;
    text-align: center;
    position: relative;
  }
  .footer-li img {
    display: block;
    width: 19.5px;
    height: 19.5px;
    margin: 5px auto;
  }

  .footer-li .active-status {
    position: absolute;
    display: none;
    left: 50%;
    margin-left: -9.75px;
    top: 0;
    z-index: 10;
    background-color: #fff;
  }

  .footer-nav .active a {
    color: #df0024;
  }

  .footer-nav .active .active-status {
    display: block;
  }

  .add-address {
    width: 110px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: none;
    color: #fff;
    border-radius: 36px;
    margin-top: 8px;
    margin-right: 10px;
  }
  footer .button,
  .button-wapper {
    width: 110px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #e7c527;
    color: #fff;
    border-radius: 36px;
    margin-top: 8px;
    margin-right: 10px;
  }
  footer .button a,
  .button-wapper a {
    color: #fff;
  }

  footer .button {
    background-color: #df0024;
  }

  .footer-nav {
    padding-left: 34%;
    box-sizing: border-box;
  }

  .invoice-red-package {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    .toast {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.75);
    }
    img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
    }
  }
  footer .disable {
    background-color: #666;
    color: #fff;
    border-radius: 36px;
  }
</style>
